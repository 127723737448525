import { defineStore } from 'pinia'
import AuthService from '../services/AuthService'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') ?? '') : null,
    }
  },

  actions: {
    async login(username: string, password: string) {
      this.user = await AuthService.login(username, password)
    },

    async refreshToken() {
      this.user = await AuthService.refreshToken(this.user)
      return this.user
    },
  },
})
