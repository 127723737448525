import axios from 'axios'
import router from '../router'

const API_URL = import.meta.env.VITE_APP_BACKEND_URL ? import.meta.env.VITE_APP_BACKEND_URL : 'http://localhost:8000'

class AuthService {
  async login(username: string, password: string) {
    return axios
      .post(API_URL + '/login', {
        username: username,
        password: password,
        fcm_token: '',
      })
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
      })
      .catch((error) => {
        console.error(error)
      })
  }

  async refreshToken(user: any) {
    return axios
      .post(API_URL + '/login/refresh', {
        refresh_token: user.refresh_token,
      })
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }
        return response.data
      })
      .catch((error) => {
        console.error(error)
        localStorage.removeItem('user')
        router.push('/auth/login')
      })
  }

  logout() {
    localStorage.removeItem('user')
  }
}

export default new AuthService()
